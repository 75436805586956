<template>
  <div>
    <div
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <div
        style="
          height: 36px;
          width: 100%;
          display: flex;
          align-items: center;
          background-color: var(--v-secondary-base) !important;
          background: var(--v-secondary-base) !important;
        "
      >
        <span style="font-size: 16px" class="ml-2"
          ><b>Products & Services</b></span
        >
      </div>
      <v-container style="height: 100%; width: 100%">
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="mx-0 px-0">
            <v-card flat class="infoCards" :loading="loading">
              <v-card-text>
                <!-- <v-list dense subheader style="min-height: 45vh">
                  <v-subheader style="font-size: 14px">
                    <v-icon small class="mr-2">storefront</v-icon>
                    Bundles</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item v-if="bundles.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No bundles available</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="bundle in bundles" :key="bundle.id">
                    <v-list-item-action> </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bundle.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        class="text-wrap"
                      >
                        {{ bundle.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->

                <v-list dense subheader style="min-height: 45vh">
                  <v-subheader style="font-size: 14px">
                    <v-icon small class="mr-2">sell</v-icon>
                    Products & Services
                    <v-btn
                      icon
                      color="primary"
                      class="ml-2"
                      @click="addProduct()"
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn></v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item v-if="productServices.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No products/services available</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="product in productServices"
                    :key="product.id"
                    @click="editProduct(product)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-avatar v-if="product.icon" class="mr-2" size="20">
                          <v-img :src="product.icon" contain></v-img>
                        </v-avatar>
                        {{ product.name }}
                        <v-chip x-small style="font-size: 11px" class="ml-2">
                          <v-icon
                            small
                            left
                            v-if="product.type == 'PRODUCT'"
                            color="primary"
                            >category</v-icon
                          >
                          <v-icon
                            small
                            left
                            v-else-if="product.type == 'SERVICE'"
                            color="primary"
                            >engineering</v-icon
                          >
                          {{ $Format.capitalizeFirstLetter(product.type) }}
                        </v-chip>
                      </v-list-item-title>

                      <v-list-item-subtitle
                        style="font-size: 11px"
                        class="text-wrap"
                      >
                        {{ product.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar tile size="100" v-if="product.image">
                        <v-img :src="product.image" contain></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6"> </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="productModal" width="800px">
      <v-card
        style="
          background: rgba(0, 0, 0, 0.3) !important;
          background-color: rgba(0, 0, 0, 0.3) !important;
          border-radius: 16px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 0px;
        "
      >
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span v-if="product && product.id"> Edit </span>
            <span v-else> Add </span>
            Product
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="saveProduct"
            :loading="savingProduct"
            color="primary"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="product && product.id"
            @click="deleteProduct(product)"
            :loading="deletingProduct"
            color="red"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn @click="productModal = false" icon
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="my-0 py-0">
          <v-container style="width: 100%">
            <v-row>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-list dense class="my-0 py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="product.code"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Product Code </span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    v-model="product.name"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Name </span>
                    </template>
                  </v-text-field>

                  <v-text-field
                    dense
                    outlined
                    v-model="product.url"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Website Link </span>
                    </template>
                  </v-text-field>
                </v-list>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-list dense subheader class="my-0 py-0">
                  <!-- <div style="width: 100%" class="text-center">
                     -->
                     <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Level
                      </v-list-item-title>
                    </v-list-item-content>
                      <v-list-item-action>
                        <v-btn-toggle
                    mandatory
                    outlined
                    v-model="product.level"
                    rounded
                    color="orange"
                  >
                  <v-btn small outlined value="SHIPMENT">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'SHIPMENT' ? 'orange' : 'primaryText'
                        "
                        >directions_boat</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Shipment</span
                      >
                    </v-btn>
                    <v-btn small outlined value="CONTAINER">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'CONTAINER' ? 'orange' : 'primaryText'
                        "
                        >widgets</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Container</span
                      >
                    </v-btn>
                   
                  </v-btn-toggle>
                      </v-list-item-action>
                      </v-list-item>
                     <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Type
                      </v-list-item-title>
                    </v-list-item-content>
                      <v-list-item-action>
                        <v-btn-toggle
                    mandatory
                    outlined
                    v-model="product.type"
                    rounded
                    color="blue"
                  >
                    <v-btn small outlined value="PRODUCT">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'PRODUCT' ? 'primary' : 'primaryText'
                        "
                        >category</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Product</span
                      >
                    </v-btn>
                    <v-btn small outlined value="SERVICE">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'SERVICE' ? 'primary' : 'primaryText'
                        "
                        >engineering</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Service</span
                      >
                    </v-btn>
                  </v-btn-toggle>
                      </v-list-item-action>
                      </v-list-item>
                  <!-- </div> -->
                  
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Image
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.image
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingImage"
                            :style="{
                              border:
                                product.image || product.imageData
                                  ? 'none'
                                  : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('image')"
                          >
                            <v-img
                              v-if="product.imageData"
                              :src="product.imageData.data"
                              contain
                            >
                            </v-img>
                            <v-img
                              v-else-if="product.image"
                              :src="product.image"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Image</span
                        ><span v-else>Add Product Image</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Icon
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.image
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingImage"
                            :style="{
                              border:
                                product.icon || product.iconData
                                  ? 'none'
                                  : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('icon')"
                          >
                            <v-img
                              v-if="product.iconData"
                              :src="product.iconData.data"
                              contain
                            >
                            </v-img>
                            <v-img
                              v-else-if="product.icon"
                              :src="product.icon"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Image</span
                        ><span v-else>Add Product Icon</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Icon
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.icon
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingIcon"
                            :style="{
                              border: product.icon
                                ? 'none'
                                : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('icon')"
                          >
                            <v-img
                              v-if="product.icon"
                              :src="product.icon"
                              height="100px"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Icon</span
                        ><span v-else>Add Product Icon</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item> -->
                </v-list>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-textarea
                  dense
                  outlined
                  v-model="product.description"
                  hide-details
                  class="my-1"
                  clearable
                  auto-grow
                  counter
                >
                  <template v-slot:label>
                    <span style="font-size: 13px"> Description </span>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <input
      id="imageUpload"
      type="file"
      hidden
      accept="image/jpeg, image/jpg, image/png, image/svg+xml"
      @change="imageChange($event)"
    />
    <input
      id="iconUpload"
      type="file"
      hidden
      accept="image/jpeg, image/jpg, image/png, image/svg+xml"
      @change="iconChange($event)"
    />
  </div>
</template>
    <script>
export default {
  data: () => ({
    bundles: [],
    deletingProduct: false,
    loading: true,
    loadingIcon: false,
    loadingImage: false,
    productServices: [],
    product: {},
    productModal: false,
    savingProduct: false,
  }),
  created() {
    this.load();
  },
  methods: {
    addProduct() {
      this.product = {
        level: 'SHIPMENT',
        type: "PRODUCT",
        code: null,
        name: null,
        description: null,
        url: null,
        image: null,
        icon: null,
      };
      this.productModal = true;
    },
    deleteProduct(item) {
      this.$confirm(
        "Are you sure you want to delete this product?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        this.deletingProduct = true;
        await this.$API.updateProductService({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
        this.$message.success("Product deleted successfully!");
        let findProduct = this.productServices.findIndex(
          (x) => x.id == item.id
        );
        this.productModal = false;
        this.deletingProduct = false;
        this.productServices.splice(findProduct, 1);
      });
    },
    editProduct(item) {
      this.product = item;
      this.productModal = true;
    },
    async load() {
      this.loading = true;
      this.bundles = await this.$API.getProductBundles();
      this.productServices = await this.$API.getProductServices();
      this.loading = false;
    },
    openFileExplorer(val) {
      if (val == "image") {
        document.getElementById("imageUpload").click();
      }
      if (val == "icon") {
        document.getElementById("iconUpload").click();
      }
    },
    async iconChange(x) {
      let file = x.target.files[0];
      this.loadingIcon = true;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.toDataUrl(url, (data) => {
        this.product.iconData = {
          data: data,
          type: file.type,
          name: file.name,
        };
        this.loadingIcon = false;
      });
    },
    async imageChange(x) {
      let file = x.target.files[0];
      this.loadingImage = true;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.toDataUrl(url, (data) => {
        this.product.imageData = {
          data: data,
          type: file.type,
          name: file.name,
        };
        this.loadingImage = false;
      });
    },
    async saveProduct() {
      this.savingProduct = true;
      if (this.product.id) {
        await this.$API.updateProductService(this.product);
      } else {
        await this.$API.createProductService(this.product);
      }
      this.productModal = false;
      this.savingProduct = false;
      this.load();
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
  },
};
</script>
<style scoped>
.infoCards {
  background: var(--v-greyRaised-lighten1) !important;
  contain: content;
  border-radius: 10px;
}
</style>